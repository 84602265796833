//voices dialog contexts
const generalContext = {
  'atrás | volver | regresar': 'click:back-btn',
  'arriba': 'up',
  'abajo': 'dowm',
  'derecha': 'right',
  'izquierda': 'left',
  'ok | okey | entrar | seleccionar | undir | accionar | presionar | aceptar': 'enter',
};

const generalContextTopTrack = {
  'atrás | volver | regresar': 'back',
  'arriba': 'up',
  'abajo': 'dowm',
  'derecha': 'right',
  'izquierda': 'left',
  'ok | okey | entrar | seleccionar | undir | accionar | presionar | aceptar': 'enter',
};

const toptrackContext = {
  ...generalContextTopTrack,
  'explora': 'click:explora-btn',
  'buscar': 'click:search-btn',
  'comunidad': 'click:live-btn',
  'en fila': 'click:player-btn',
  'usa tu celular | conectar celular': 'click:connect-btn',
  'perfil | mi perfil': 'click:profile-btn',
  'cantar ahora | canción del dia': 'click:daySong-btn',
  'activar kanto score | activar score': 'click:activateScore-btn',
  'iniciar sesión': 'click:login-btn',
  'regístrarse | regístrate gratis | regístrate | regístrarme': 'click:register-btn',
};

const playlistContext = {
  ...generalContext,
  'en fila | reproducir la fila | reproducir en fila | reproducir fila': 'click:btn-your-smartlist',
};

const liveContext = {
  ...generalContext,
  'cántala en tu tv | cantar en tv | cantar en televisión | cántala en tu televisión': 'click:singOnTv-live-btn',
  'me gusta | like': 'click:like-live-btn',
  'seguir | follow | unfollow | dejar de seguir': 'click:follow-live-btn',
};

const playerContext = {
  ...generalContext,
  'listo': 'click:onBoarding-player-btn',
  'conectar | conéctate | contectar celular': 'click:phone-top-btn',
  'score | inciar score | kanto score | puntación | inciar puntuación': 'click:score-btn',
  'tonos | tones': 'click:tones-btn',
  'voz guia | guia': 'click:voice-btn',
  'canción anterior | anterior canción | anterior': 'click:btn-prev-pc',
  'play | pause | pausa | pausar | reproducir | reproduce | pausar canción | reproducir canción': 'click:defaultOption',
  'canción siguiente | siguiente canción | siguiente': 'click:btn-next-pc',
  'agregar a favoritos | quitar de favoritos | favoritos': 'click:favorite-player-btn',
  'like | me gusta': 'click:like-player-btn',
  'no me gusta | dislike': 'click:dislike-player-btn',
};

const connectPhoneContext = {
  ...generalContext,
  'vincular con wifi | vincular con wi-fi | vincular con wi fi | otra opción': 'click:connect-btn',
};

const connectPhoneWiFiContext = {
  ...generalContext,
  'vincular con código de tv | vincular con código de televisión | otra opción': 'click:connect-wifi-btn',
};

const profileContext = {
  ...generalContext,
  'favoritos | favoritas': 'click:favorites-btn',
  'cuenta': 'click:account-btn',
  'cántala en tu tv | cantar en televisión | cantar en tv | cántala en tu televisión': 'click:singOnTv-live-btn',
};

const accountContext = {
  ...generalContext,
  'cerrar sesión': 'click:logout-btn',
  'vuelvete premium | ser premium | volverme premium': 'click:account-subscription-btn',
};

const favoritesContext = {
  ...generalContext,
  'en fila | reproducir fila | reproducir la fila | reproducir en fila': 'click:favorites-player-btn',
  'canciones': 'click:favorites-songs-btn',
  'playlist | playlists': 'click:favorites-playlist-btn',
  'artistas | artista': 'click:favorites-artista-btn',
};

const welcomeContext = {
  ...generalContextTopTrack,
  'start now | comenzar ahora | comenzar | vuélvete premium | premium | registrarse | get premium | be premium': 'click:btnAction1',
  'inciar sesión | log in | login | cerrar sesión | log out | logout': 'click:btnAction2',
};

const loginTvContext = {
  ...generalContext,
  'recuperar contraseña | olvidé mi contraseña | contraseña olvidada | forgot password': 'click:tab-recovery',
  'iniciar con facebook | facebook | inicia con facebook | inicio con facebook': 'click:sign-facebook-btn',
  'iniciar con web | iniciar desde una web | inicio con web | inicio desde una web | inicia desde una web': 'click:sign-web-btn',
  'continuar | next': 'click:next-btn',
  'iniciar sesión': 'click:submit-signIn-btn',
  'registrarse': 'click:submit-signUp-btn',
  'anterior | previous': 'click:prev-btn',
};

const loginWebContext = {
  ...generalContext,
  'ingresa con facebook | facebook': 'click:login-web-fb',
  'ingresa con control remoto | control remoto | ingresa con el control remoto': 'click:login-web-control',
};

const registerWebContext = {
  ...generalContext,
  'regístrate con facebook | facebook': 'click:login-web-fb',
  'regístrate con control remoto | control remoto | regístrate con el control remoto': 'click:login-web-control',
};

const loginTvRecoveryContext = {
  ...generalContext,
  'enviar | send': 'click: btn-send-recoverpass',
  'anterior | previous': 'click:prev-btn',
};

export const voiceContexts = {
  generalContext,
  toptrackContext,
  accountContext,
  favoritesContext,
  playerContext,
  playlistContext,
  profileContext,
  connectPhoneContext,
  connectPhoneWiFiContext,
  liveContext,
  welcomeContext,
  loginTvContext,
  loginTvRecoveryContext,
  loginWebContext,
  registerWebContext,
};
