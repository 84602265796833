import { Injectable } from '@angular/core';
import { Platform } from '@providers/platform/platform';

declare let gtag: any;

@Injectable()
export class GoogleAnalyticsService {
  track;
  isGtagInitialized = false;

  constructor(private platform: Platform) {}

  initializeGtag() {
    if (this.platform.isBrowser()) {
      if (gtag) {
        this.isGtagInitialized = true;
        console.log('gtag is initailized?:', this.isGtagInitialized);
        return;
      }
      this.isGtagInitialized = false;
      //throw new Error('gtag is not initialized');
      console.log('gtag is initailized?:', this.isGtagInitialized);
    }
  }

  gEvent(eventName: string, eventParams?: Record<string, any>) {
    if (this.isGtagInitialized) {
      return gtag('event', eventName, eventParams);
    }
  }

  gtag(
    command: 'config' | 'event' | 'get' | 'set' | 'js' | 'consent',
    eventName: string,
    eventParams?: Record<string, any>
  ) {
    if (this.isGtagInitialized) {
      return gtag(command, eventName, eventParams);
    }
  }

  // SEGUIMIENTO CON GOOGLE ANALITICS
  activeTrackingGoogleAnalitics(cb) {
    (window as any).ga('create', 'UA-46312170-5', 'auto');
    // ga('send', 'pageview');
    this.track = (window as any).ga;
    cb((window as any).ga);
  }
}
