import { mapKeys } from 'lodash-es';

import { Basic } from './device-platform';
import { IDevicePlatform } from './device-platform.interface';

export class Zeasn extends Basic implements IDevicePlatform {
  name = 'zeasn';
  type = 'zeasn';

  async onInit() {
    const userAgent = navigator.userAgent;
    const smarttvPlatform = [];
    const substrPos = userAgent.search(/SmartTvA/i);
    if (substrPos > -1) {
      this.deviceObject = this.deviceObject ? this.deviceObject : {};
      smarttvPlatform['version'] = userAgent.substr(substrPos + 9, 6);
      const dataDevice = {
        type: userAgent,
        manufacturer: 'Smart TV Alliance',
        version: userAgent.substr(substrPos + 9, 6),
        major: parseInt(smarttvPlatform['version'].split('.')[0], 10),
        minor: parseInt(smarttvPlatform['version'].split('.')[1], 10),
        revision: parseInt(smarttvPlatform['version'].split('.')[2], 10),
        platform: 'zeasn',
      };

      for (const key of Object.keys(dataDevice)) {
        this.deviceObject[key] = dataDevice[key];
      }

      this.deviceData = mapKeys(dataDevice, (value, key) => 'zeasn_' + key);
    }
    super.onInit();
    return Promise.resolve(this.deviceData);
  }

  onResume(router) {}
  exit() {
    const userAgent = navigator.userAgent;
    const smartTvPlatform = [];
    const substrPos = userAgent.search(/SmartTvA/i);
    if (substrPos > -1) {
      smartTvPlatform['type'] = userAgent;
      smartTvPlatform['manufacturer'] = 'Smart TV Alliance';
      smartTvPlatform['version'] = userAgent.substr(substrPos + 9, 6);
      smartTvPlatform['major'] = parseInt(smartTvPlatform['version'].split('.')[0], 10);
      smartTvPlatform['minor'] = parseInt(smartTvPlatform['version'].split('.')[1], 10);
      smartTvPlatform['revision'] = parseInt(smartTvPlatform['version'].split('.')[2], 10);
    }
    smartTvPlatform['Platform'] = 'Zeasn';

    if (typeof (window as any).SmartTvA_API !== 'undefined' && smartTvPlatform['major'] >= 3) {
      (window as any).SmartTvA_API.exit();
    } else {
      window.history.go(-999);
      window.close();
    }
    return;
  }
}
