import { Basic } from './device-platform';
import { IDevicePlatform } from './device-platform.interface';
import { Movistar } from './movistar-device';
import { NetCast } from './netcast-device';
import { Tizen } from './tizen-device';
import { WebApp } from './web-app.device';
import { Webos } from './webos-device';
import { Zeasn } from './zeasn-device';

const devicePlatformFactory = async (type: string): Promise<IDevicePlatform> => {
  let devicePlatform: IDevicePlatform;
  switch (type) {
    case 'basic': {
      devicePlatform = new Basic();
      devicePlatform.setNameWithOperatingSystem();
      break;
    }
    case 'movistar': {
      devicePlatform = new Movistar();
      break;
    }
    case 'netcast': {
      devicePlatform = new NetCast();
      break;
    }
    case 'webos': {
      devicePlatform = new Webos();
      break;
    }
    case 'samsung': {
      devicePlatform = new Tizen();
      break;
    }
    case 'tizen': {
      devicePlatform = new Tizen();
      break;
    }
    case 'webapp': {
      devicePlatform = new WebApp();
      break;
    }
    case 'zeasn': {
      devicePlatform = new Zeasn();
      break;
    }
    default: {
      devicePlatform = new Basic();
      devicePlatform.setNameWithOperatingSystem();
      return devicePlatform;
    }
  }
  await devicePlatform.onInit();
  return devicePlatform;
};

export { devicePlatformFactory };
