import { Injectable } from '@angular/core';
import { clone, find, findIndex, forEach } from 'lodash-es';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

import { IMedia } from '../../interfaces/interfaces';
import { CountryService } from '../country/country';
import { KSHelpers } from '../helpers/ks-helpers';
import { Platform } from '../platform/platform';
import { RootScopeAdapter } from '../root-scope-adapter/root-scope-adapter';
import { ProgressManager } from './progress-manager';
import { VideoJSServiceInterface } from './videojs.service.interface';
@Injectable()
export class PlayerService {
  private static readonly URL_BREAK_PLAYER = ['localhost'];

  onLoadVideo: Subject<any> = new Subject();
  onReadyVideo: Subject<any> = new Subject();
  onProgress: Subject<any> = new Subject();
  onTimeSeconds: Subject<any> = new Subject();
  playerState: Subject<boolean> = new Subject();
  deleteSong: Subject<IMedia> = new Subject();
  onNextSong: Subject<IMedia> = new Subject();
  onPlayClip: Subject<any> = new Subject();
  onPreviusSong: Subject<IMedia> = new Subject();
  onErrorVideo: Subject<IMedia> = new Subject();
  onFinishSong: Subject<IMedia> = new Subject();
  onFinishAll: Subject<any> = new Subject();
  onFullyLoadedVideo: Subject<any> = new Subject();
  onUpdateFavoriteOneSong: Subject<any> = new Subject();
  playing$: BehaviorSubject<boolean> = new BehaviorSubject(false) as BehaviorSubject<boolean>;

  get playing() {
    return this.playing$.value;
  }

  onTimeLess10: Subject<any> = new Subject();
  onTimeM5: Subject<any> = new Subject();
  onTime30: Subject<IMedia> = new Subject();

  onTimeM10: Subject<IMedia> = new Subject();
  onTimeM80: Subject<IMedia> = new Subject();

  // let urlBreakPlayer=[];
  // 'localhost','karaokesmart.ngrok.io','192.168.1.19'
  intervalLoading;
  timeLoading = 0;

  quality = { heigth: 0, width: 0 };

  errorFlag = false;

  private currentClip: IMedia = {} as IMedia;

  private videojs;

  private pl: any; //videojs.Player;
  private list: IMedia[] = [];
  private auxList;
  private currentVideo;

  private nextToDel; // Proximo a eliminar
  private indexSongCurrent = 0;
  private playThisBand = false;

  private progressManager: ProgressManager;

  constructor(
    public helpers: KSHelpers,
    private country: CountryService,
    private rootScopeAdapter: RootScopeAdapter,
    private platform: Platform
  ) {
    if (this.platform.isBrowser()) {
      this.onUpdateFavoriteOneSong.subscribe(data => {
        this.currentClip.like = data.value;
      });
    }
    this.progressManager = new ProgressManager(this, this.country, this.rootScopeAdapter);
  }

  getDomPlayer() {
    return this.pl;
  }

  getList() {
    return this.list;
  }

  setList(list: IMedia[]) {
    this.list = list;
  }

  setNextToDel(song: IMedia) {
    this.nextToDel = song;
  }

  loadVideoJS(videoService: VideoJSServiceInterface) {
    if (!!this.videojs) {
      return;
    }
    try {
      this.videojs = videoService.getVideoJS();
      this.videojs.use('*', () => ({
        setSource: (srcObj, next) => {
          // console.log('src obj es este', srcObj);
          // pass null as the first argument to indicate that the source is not rejected
          next(null, srcObj);
        },
      }));
    } catch (e) {
      console.log(' no carga videojs');
    }
  }

  getCurrentClip() {
    return this.currentClip;
  }

  setCurrentClip(song: IMedia) {
    this.currentClip = song;
  }

  onFinish = async () => {
    // console.log('ocurre on finish player service');
    this.onFinishSong.next();

    if (this.currentClip.mode !== 'game') {
      this.processNextSong();

      if (this.list.length <= 1) {
        this.onFinishAll.next();
        this.indexSongCurrent = 0;
      }
    }
  };

  async processNextSong() {
    const songToDelete = clone(this.currentClip);
    if (this.list.length > 1) {
      await this.next(songToDelete.uid);
    }
    this.deleteSong.next(songToDelete);
  }

  onReadyPlayer(callback: () => any): Subscription {
    return this.playerState.subscribe(ready => {
      if (ready) {
        callback();
      }
    });
  }

  getIndexSongCurrent() {
    return this.indexSongCurrent;
  }
  setIndexSongCurrent(position) {
    if (-1 < position) {
      this.indexSongCurrent = position;
    }
  }

  makeActive(clip: IMedia) {
    let temp;
    forEach(this.list, (s, i) => {
      if (s.uid === clip.uid) {
        s.activeTab = true;
      } else {
        s.activeTab = false;
        if (this.nextToDel) {
          if (this.nextToDel.uid === s.uid) {
            temp = i;
          }
        }
      }
    });
    if (temp !== undefined) {
      this.list.splice(temp, 1);
      this.nextToDel = null;
    }
  }
  getRandomize() {
    // generate a number between 1 a 10000
    return Math.floor(Math.random() * 10000 + 1);
  }

  initialize(divId: string, opt, videoJSService: VideoJSServiceInterface) {
    this.loadVideoJS(videoJSService);

    this.indexSongCurrent = this.indexSongCurrent || 0;
    if (this.list.length === 0) {
      return;
    }
    // en caso no haya cancion por error de la url
    this.setCurrentClip(this.list[this.indexSongCurrent]);

    try {
      const container = document.getElementById(divId);

      const playerOptions = {
        autoplay: true,
        controls: false,
        sources: this.list[this.indexSongCurrent].sources,
      };

      this.pl = this.videojs(container, playerOptions, () => {
        // console.log('onPlayerReady', this.pl);
      });

      const onLoadMeta = ev => {
        this.currentClip.duration = this.pl.duration();
        this.onReady(ev, null, this.currentClip);
      };

      this.pl.on('loadedmetadata', onLoadMeta);

      this.pl.on('load', (e, api, data) => {
        this.onLoadVideo.next();
      });
      this.makeActive(this.currentClip);
      this.pl.on('timeupdate', this.progressManager.onProgressActions.bind(this.progressManager));
      // this.pl.on('ready', this.onReady.bind(this));

      this.pl.on('error', this.onError.bind(this));
      // this.pl.on('contenterror', this.onError.bind(this));
      this.pl.off('ended', this.onFinish);
      this.pl.on('ended', this.onFinish);
    } catch (e: any) {
      this.onErrorVideo.next(e);
    }
  }

  afterUnload() {
    // servicios del player al parecer
    this.progressManager.resetTimeNotifier();
    this.list = this.auxList;
    this.auxList = null;
  }

  onReady(ev, config, clip: IMedia) {
    clip.lastTimeSended = 0;
    this.cancelTimeLoading();
    this.timeLoading = 0;
    this.resetTimeNotifier();
    this.setCurrentClip(clip);
    this.progressManager.setProgressAux(0);
    this.progressManager.setBufferAux(0);
    this.makeActive(clip);
    // console.log("ready",clip);
    this.errorFlag = false;

    this.onPlayClip.next({
      clip,
      mixpanel: {
        artist: clip.artist,
        song: clip.name,
        idSong: clip.id,
        namePlaylist: clip.namePlaylist ? clip.namePlaylist : 'fuera de toptrack',
        tiene_tono: !!clip.current_tone,
        tono: clip.current_tone || 0,
        provider: clip.provider,
        video: clip.src,
        where: clip.where || null,
        search_keyword: clip.search_keyword || null,
      },
    });
  }

  onError(element) {
    const error = this.pl?.error() || {
      code: 'NO_CODE',
      message: 'NO_MESSAGE',
    };

    this.onErrorVideo.next(error);

    if (error.code === 4 && !this.errorFlag) {
      this.errorFlag = true;
      this.pl?.pause();
      this.pl?.src(this.currentClip.sources);
      this.pl?.play();
    }
  }

  shutdown() {
    this.playing$.next(false);
    this.cancelTimeLoading();
    this.cancelLoadToback();
    this.resetTimeNotifier();

    this.indexSongCurrent = 0;
    // this.pl.shutdown();
    this.pl?.dispose();
  }
  findByUid(uid) {
    return song => song.uid === uid;
  }
  deleteSongForPosition(posiSong) {
    const songDeleted = this.list.splice(posiSong, 1);
    if (!this.playThisBand && songDeleted && songDeleted.length) {
      // let posiuid = this.list.indexOf(
      //   this.list.filter(this.findByUid(songDeleted[0].uid))[0]
      // );
      const songToDelete = find(this.list, { uid: songDeleted[0].uid });
      this.deleteSong.next(songToDelete);
      // this.list.splice(posiuid, 1);
    }
  }

  seekPerc(perc) {
    const to = this.currentClip.duration * perc * 0.01;
    // console.log(to, 'this.pl.seek', this.pl.seek);
    // this.pl.seek(to);
    const duration = this.pl.duration() || 0;
    this.pl.currentTime(duration * to);
  }

  load(song: IMedia) {
    if (song) {
      if (typeof song.like !== 'undefined') {
        this.updateFavoriteOneSong(song);
      }
      // this.pl.src(song.sources);
      if (this.pl) {
        console.log('loooog', song.sources[0], 'song es', song);
        this.pl.src(song.sources[0].src);
        this.pl.load();
        this.setCurrentClip(song);
      } else {
        throw new Error('Player not initialized');
      }
    }
  }

  updateFavoriteOneSong(song) {
    this.onUpdateFavoriteOneSong.next({
      value: song.like,
    });
  }

  getPlayThisBand() {
    return this.playThisBand;
  }

  getUid(idSong) {
    return idSong + '_' + new Date().getTime() + '_' + this.getRandomize();
  }

  cancelLoadToback() {
    try {
      if (this.pl) {
        this.pl.pause();
        // this.pl.unload();
        this.pl.src(null);
        this.pl.dispose();
      }
    } catch (e) {
      console.log(e);
    }
  }
  cancelTimeLoading() {
    clearInterval(this.intervalLoading);
  }
  /**
   * next : funcion que reproduce la siguiente cancion en el smartlist, si se recibe uid se da
   * siguiente cancion apartir de la cancion dada.
   *
   * @returns si logro dar siguiente retorna TRUE, sino FALSE
   */
  next(uid?: string): Promise<IMedia> {
    const uidInitToNext = uid ? uid : this.currentClip.uid;
    const currentSongIndex = findIndex(this.list, { uid: uidInitToNext });
    const nextSongIndex = currentSongIndex === this.list.length - 1 ? 0 : currentSongIndex + 1;
    if (currentSongIndex >= 0) {
      this.indexSongCurrent = nextSongIndex;
      const nextSong = this.list[nextSongIndex];
      this.onNextSong.next(nextSong);
      return Promise.resolve(nextSong);
    }
    return Promise.reject(null);
  }
  /**
   * previus : funcion que reproduce la siguiente cancion en el smartlist, si se recibe uid se da
   * siguiente cancion apartir de la cancion dada.
   *
   * @returns si logro dar siguiente retorna TRUE, sino FALSE
   */
  previus(uid?: string): Promise<IMedia> {
    const uidInitToPrev = uid ? uid : this.currentClip.uid;
    const currentSongIndex = findIndex(this.list, { uid: uidInitToPrev });
    const prevSongIndex = currentSongIndex === 0 ? this.list.length - 1 : currentSongIndex - 1;
    if (currentSongIndex >= 0) {
      this.indexSongCurrent = prevSongIndex;
      const previousSong = this.list[prevSongIndex];
      this.onPreviusSong.next(previousSong);
      return Promise.resolve(previousSong);
    }
    return Promise.reject(null);
  }

  /**
   * play: play current song
   */
  play(): Promise<boolean> {
    if (this.pl) {
      this.pl.play();
      return Promise.resolve(true);
    }
    return Promise.reject(false);
  }

  /**
   * pause: pause current song
   */
  pause(): Promise<boolean> {
    if (this.pl) {
      this.pl.pause();
      this.playing$.next(false);
      return Promise.resolve(true);
    }
    return Promise.reject(false);
  }

  getNextSong() {
    const actualSongIndex = findIndex(this.list, (s: any) => s.uid === this.currentClip.uid);
    // console.log(actualSongIndex, that.currentClip, 'that.currentClip');
    if (actualSongIndex + 1 < this.list.length) {
      return this.list[actualSongIndex + 1];
    } else {
      return null;
    }
  }

  activateSong(clip) {
    this.makeActive(clip);
  }

  playSong(song: IMedia) {
    this.load(song);
    this.resetTimeNotifier();
  }

  resetTimeNotifier() {
    this.progressManager.resetTimeNotifier();
  }
}
