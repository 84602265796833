import { KsResponse } from '@interfaces/ks-response.interface';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

export const proccessBackendResponse: <T>() => (source: Observable<any>) => Observable<T> =
  <T>() =>
  (source: Observable<any>) =>
    source.pipe(
      switchMap((response: KsResponse<T>) => {
        if (response.success === true) {
          return of(response.data);
        } else {
          return throwError(response.message ? response : { code: 'unknown', message: 'Ocurrio un error inesperado' });
        }
      }),
      catchError(error => {
        if (error.status) {
          return throwError(error.message ? error : { code: error.status, message: 'Ocurrio un error inesperado' });
        }
        return throwError(error.message ? error : { code: 'unknown', message: 'Ocurrio un error inesperado' });
      })
    );
