import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WebBannerComponent } from './web-banner.component';

@NgModule({
  declarations: [WebBannerComponent],
  imports: [CommonModule],
  exports: [WebBannerComponent],
})
export class WebBannerModule {}
