const ramdomColors = [
  { background: '#f44336', color: '#ffffff' },
  { background: '#e91e63', color: '#ffffff' },
  { background: '#9c27b0', color: '#ffffff' },
  { background: '#673ab7', color: '#ffffff' },
  { background: '#3f51b5', color: '#ffffff' },
  { background: '#2196f3', color: '#ffffff' },
  { background: '#03a9f4', color: '#ffffff' },
  { background: '#00bcd4', color: '#ffffff' },
  { background: '#009688', color: '#ffffff' },
  { background: '#4caf50', color: '#ffffff' },
  { background: '#8bc34a', color: '#ffffff' },
  { background: '#cddc39', color: '#ffffff' },
  { background: '#ffeb3b', color: '#ffffff' },
  { background: '#ffc107', color: '#ffffff' },
];

export const getRamdomColor: () => { background: string; color: string } = () => {
  const ramdomColor = ramdomColors[Math.floor(Math.random() * ramdomColors.length)];
  return ramdomColor;
};

export const getRamdomNumber = () => {
  // 20 is the max
  const max = 20;
  const min = 1;
  const ramdomNumber = Math.floor(Math.random() * (max - min + 1) + min);
  return ramdomNumber;
};

export const getRamdomImageLink = () => {
  const ramdomNumber = getRamdomNumber();
  const link = `../../../../assets/img/ramdong-web-app-images/${ramdomNumber}.png`;
  return link;
};
