import { Injectable } from '@angular/core';
import { IMedia } from '@interfaces/interfaces';
import { Observable } from 'rxjs';
import { map, share, take } from 'rxjs/operators';

import { Api } from '../api/api';
import { proccessBackendResponse } from '../helpers/backend';

@Injectable()
export class SongsService {
  constructor(private api: Api) {}

  catalog(data): Promise<any> {
    return this.api.post('search/new_catalog', data).toPromise();
  }

  findAll(ctx) {
    ctx = ctx || { where: { cat: null } };
    // this.topTrack.get(ctx);
  }

  findById(id: string | number): Observable<IMedia> {
    return this.api
      .get('song/' + id + '/info', undefined, {
        headers: {
          platform: 'smart_tv',
        },
      })
      .pipe(proccessBackendResponse<IMedia>());
  }

  findByTag(tag: string): Observable<IMedia> {
    return this.api.post('song/tag', { tag }).pipe(proccessBackendResponse<IMedia>(), share());
  }
}
