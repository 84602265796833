import { IMedia } from 'src/interfaces/interfaces';

export const getSourceAndTone = (song: IMedia, tone?: string, voice?: boolean) => {
  // defaul tone
  if (tone === undefined || parseInt(tone, 10) === 0 || (song && !song.tones)) {
    let videoUrl = '';

    if (voice) {
      videoUrl = song.video_voice;
    } else if (song.hls && song.hls['0']) {
      videoUrl = song.hls['0'];
      // replace http with https
      videoUrl = videoUrl.replace('http://', 'https://');
    } else {
      videoUrl = song.video;
    }

    return [
      {
        type: song.hls ? 'application/x-mpegurl' : 'video/mp4',
        src: videoUrl,
      },
    ];
  } else {
    let videoUrl = '';
    if (song.hls && song.hls[tone]) {
      videoUrl = song.hls[tone];
      // replace http with https
      videoUrl = videoUrl.replace('http://', 'https://');
    } else {
      videoUrl = song.video;
    }
    return [
      {
        type: song.hls ? 'application/x-mpegurl' : 'video/mp4',
        src: videoUrl || null,
      },
    ];
  }
};

export const canPlayThisSong = (song: IMedia) => true;
