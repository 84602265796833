import { Injectable } from '@angular/core';
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-internal-modules
import { CastReceiverContext, CastReceiverOptions } from '@app/../typings/chromecast-caf-receiver/cast.framework';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Subscription } from 'rxjs';

import { DeviceKS } from '../device-ks/device-ks';
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-internal-modules
import { loadDynamicScript } from '../dynamic-js-loader/dynamic-js-loader';
import { LocationService } from '../helpers/location';
import { LocalStorage } from '../local-storage/local-storage';
import { SessionService } from '../user/session.service';

@Injectable()
export class ChromecastService {
  chromeCastContext: CastReceiverContext;
  CUSTOM_CHANNEL = 'urn:x-cast:co.karaokesmart';
  sessionSubscription: Subscription;
  defaultOptions: CastReceiverOptions;
  constructor(
    private device: DeviceKS,
    private location: LocationService,
    private localstorage: LocalStorage,
    private session: SessionService
  ) {}

  init() {
    loadDynamicScript('//www.gstatic.com/cast/sdk/libs/caf_receiver/v3/cast_receiver_framework.js', 'chromecast_script')
      .toPromise()
      .then(async () => {
        this.device.setDeviceType('chromecast');
        await this.localstorage.setItem('device_platform', 'chromecast');
        const cast = (window as any).cast;
        const chromeCastID = 78587080;
        this.chromeCastContext = cast.framework.CastReceiverContext.getInstance() as CastReceiverContext;
        // const playerManager = this.chromeCastContext.getPlayerManager();
        // this.chromeCastContext.getPlayerManager()
        this.listenMessages();

        this.defaultOptions = new cast.framework.CastReceiverOptions();
        this.defaultOptions.maxInactivity = 6000; // Development only
        this.defaultOptions.customNamespaces = {
          [this.CUSTOM_CHANNEL]: cast.framework.system.MessageType.JSON,
        };

        this.listenDevices();
        this.chromeCastContext.setLoggerLevel(cast.framework.LoggerLevel.DEBUG);
      });
  }

  listenMessages() {
    this.chromeCastContext.addCustomMessageListener(this.CUSTOM_CHANNEL, (customEvent: any) => {
      // handle customEvent.

      this.chromeCastContext.sendCustomMessage(this.CUSTOM_CHANNEL, customEvent.senderId, {
        type: 'status',
        qr: this.session.getQR(),
        token: this.session.currentSession.token,
      });
    });
  }

  listenDevices() {
    this.chromeCastContext.addEventListener(cast.framework.system.EventType.SENDER_CONNECTED, () => {
      if (this.session.session$.value) {
        this.chromeCastContext.sendCustomMessage(this.CUSTOM_CHANNEL, undefined, {
          type: 'connection',
          data: this.session.getQR(),
        });
      }
      this.sessionSubscription = this.session.session$.subscribe(session => {
        if (session) {
          // console.log(
          //   'se envia evento pero no ha cargado',
          //   this.session.getQR()
          // );
          this.chromeCastContext.sendCustomMessage(this.CUSTOM_CHANNEL, undefined, {
            type: 'connection',
            data: this.session.getQR(),
          });
          if (this.sessionSubscription) {
            this.sessionSubscription.unsubscribe();
          }
        }
      });
    });
  }

  isChromecast() {
    return (
      this.location.getUrlParameter('platform') === 'chromecast' ||
      this.localstorage.getCachedItem('device_platform') === 'chromecast'
    );
  }

  start(options?: CastReceiverOptions) {
    this.chromeCastContext.start(options || this.defaultOptions);
  }
}
