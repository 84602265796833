import { Basic } from './device-platform';
import { IDevicePlatform } from './device-platform.interface';
import { getLaunchEventData } from './devices.helper';

export class NetCast extends Basic implements IDevicePlatform {
  name = 'netcast';
  type = 'netcast';

  async onInit() {
    this.deviceObject = document.getElementById('device') || {};
    super.onInit();
  }

  getLaunchParams() {
    return getLaunchEventData();
  }

  listenRelaunch() {
    if (!!this.relaunchSubscription) {
      this.relaunchSubscription();
    }

    this.relaunchSubscription = document.addEventListener(
      'webOSRelaunch',
      () => {
        this.onLaunch();
      },
      true
    );
  }

  onLaunch() {
    const launchParamsRedirect = this.getLaunchParams();

    console.assert(launchParamsRedirect !== undefined, 'launchEvent no debería estar indefinido');

    console.assert(launchParamsRedirect);

    if (!!launchParamsRedirect) {
      this.lauchEvent.next(launchParamsRedirect);
    }
  }

  onRelaunch() {
    this.listenRelaunch();
  }

  onResume(router) {}
  exit() {
    try {
      (window as any).NetCastBack();
    } catch (e) {
      try {
        (window as any).NetCastExit();
      } catch (e2) {
        window.history.go(-999);
        window.close();
      }
    }
  }
}
