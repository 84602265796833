import { Alertify } from './alertify/alertify';
import { Api } from './api/api';
import { Config } from './config/config';
import { CookieService } from './cookies/cookies';
import { CookiesService } from './cookies/cookies.service';
import { CountryService } from './country/country';
import { DeviceKS } from './device-ks/device-ks';
import { KSHelpers } from './helpers/ks-helpers';
import { LocationService } from './helpers/location';
import { GoogleAnalyticsService } from './metrics/google-analytics/google-analytics.service';
import { HotjarService } from './metrics/hotjar/hotjar.service';
import { MetricsService } from './metrics/metrics.service';
import { Mxp } from './mxp/mxp';
import { Notifications } from './notifications/notifications';
import { Platform } from './platform/platform';
import { PreviousRouteService } from './previus-route/previus-route.service';
import { WebBannerService } from './providers';
import { RemoteConsoleService } from './remote-console/remote-console.service';
import { RootScopeAdapter } from './root-scope-adapter/root-scope-adapter';
import { AuthTokenService } from './user/auth-token.service';
import { PremiumCheckerService } from './user/premium-checker.service';
import { SessionService } from './user/session.service';
import { User } from './user/user';
import { UserRealtimeService } from './user/user-realtime';

export const boostrapProviders = [
  Alertify,
  Api,
  AuthTokenService,
  Config,
  CookieService,
  CookiesService,
  CountryService,
  DeviceKS,
  KSHelpers,
  LocationService,
  Mxp,
  HotjarService,
  MetricsService,
  Notifications,
  Platform,
  PreviousRouteService,
  // // PlaylistService,
  RootScopeAdapter,
  SessionService,
  RemoteConsoleService,
  PremiumCheckerService,
  User,
  UserRealtimeService,
  GoogleAnalyticsService,
  WebBannerService,
];
