export class TimeNotifier {
  timeM1 = false;
  timeM5 = false;
  time30 = false;
  timeM40 = false;
  timeM10 = false;
  timeM60 = false;
  timeM80 = false;

  reset() {
    this.timeM1 = false;
    this.time30 = false;
    this.timeM10 = false;
    this.timeM40 = false;
    this.timeM60 = false;
    this.timeM80 = false;
  }
}
