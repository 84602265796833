import { Injectable } from '@angular/core';

export type AllowedDevicePlatforms =
  | 'webapp'
  | 'webos'
  | 'netcast'
  | 'tizen'
  | 'zeasn'
  | 'basic'
  | 'chromecast'
  | 'movistar';

@Injectable()
export class DeviceDetectorService {
  checkIfIsWebApp(): boolean {
    // if current domain is karaoke.kanto.co or localhost
    const currentDomain = window.location.hostname;
    if (currentDomain === 'karaoke.kanto.co') {
      return true;
    }
    return false;
  }

  checkIfIsZeans(): boolean {
    const userAgent = navigator.userAgent;
    const substrPos = userAgent.search(/SmartTvA/i);
    if (substrPos > -1) {
      return true;
    } else {
      return false;
    }
  }

  checkIfTizen(): boolean {
    // return true; // 🚧 DEBUG
    return (window as any).webapis || (window as any).tizen || navigator.userAgent.indexOf('Tizen') !== -1;
  }

  checkIfMovistar(): boolean {
    const existMovistarLib = !!(window as any).laSdk;
    if (existMovistarLib) {
      return true;
    } else {
      return false;
    }
  }

  checkIfWebOS(): boolean {
    const ua = navigator.userAgent;
    const deviceObject: any = document.getElementById('device');
    if (deviceObject && deviceObject?.platform && deviceObject?.platform?.toLowerCase().indexOf('webos') !== -1) {
      return true;
    } else if (ua.indexOf('webOS') !== -1 || ua.indexOf('Web0S') !== -1) {
      return true;
    } else {
      return false;
    }
  }

  checkIfNetcast(): boolean {
    const deviceObject: any = document.getElementById('device');
    if (deviceObject && deviceObject?.platform && deviceObject?.platform?.toLowerCase().indexOf('netcast') !== -1) {
      return true;
    } else {
      return false;
    }
  }

  checkIfPlatformWithSODevice(): boolean {
    const deviceObject: any = document.getElementById('device');
    if (!!deviceObject['SO_Device']) {
      return true;
    }
    return false;
  }

  detectDeviceType(): AllowedDevicePlatforms {
    // comprobar si es tizen
    if (this.checkIfIsWebApp()) {
      return 'webapp';
    }
    if (this.checkIfTizen()) {
      return 'tizen';
    }

    if (this.checkIfMovistar()) {
      return 'movistar';
    }

    if (this.checkIfWebOS()) {
      return 'webos';
    }

    if (this.checkIfNetcast()) {
      return 'netcast';
    }

    if (this.checkIfIsZeans()) {
      return 'zeasn';
    }

    if (this.checkIfPlatformWithSODevice()) {
      const deviceObject: any = document.getElementById('device') || {};
      const platform = deviceObject['SO_Device'];
      return platform || 'basic';
    }
    return 'basic';
  }
}
