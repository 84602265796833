import { Injectable } from '@angular/core';
import { LocalStorage } from '@providers/local-storage/local-storage';
import { User } from '@providers/user/user';

@Injectable({ providedIn: 'root' })
export class SongCounterService {
  static MAX_SONGS_PER_DAY_AS_GUESS = 2;
  static MAX_SONGS_PER_DAY_AS_AUTHENTICATED_USER = 4;

  private sungSongs = 0;
  private songsSungToday = 0;
  private todayDate = new Date().toISOString().split('T')[0].replace(/-/g, '');

  constructor(private localStorage: LocalStorage, private userService: User) {
    this.checkIfTodayIsNewDay();
  }

  async checkIfTodayIsNewDay() {
    const todayDate = new Date().toISOString().split('T')[0].replace(/-/g, '');
    const todayDateCookie = await this.localStorage.getItem('todayDate');
    this.todayDate = todayDateCookie;
    if (this.todayDate !== todayDate) {
      this.todayDate = todayDate;
      this.songsSungToday = 0;
      await this.localStorage.setItem('songsSungToday', this.songsSungToday.toString());
      await this.localStorage.setItem('todayDate', this.todayDate);
    } else {
      await this.getSongsSungToday();
    }
  }

  canISingASong() {
    if (this.userService.premium) {
      return true;
    } else if (this.userService.authenticated) {
      console.log('si estoy autenticado y he cantado ' + this.songsSungToday + ' canciones');
      return this.songsSungToday < SongCounterService.MAX_SONGS_PER_DAY_AS_AUTHENTICATED_USER;
    } else {
      console.log('no estoy autenticado y he cantado ' + this.songsSungToday + ' canciones');
      return this.songsSungToday < SongCounterService.MAX_SONGS_PER_DAY_AS_GUESS;
    }
  }

  singSong() {
    this.sungSongs++;
    // add song to today cookie
    if (this.songsSungToday === 0) {
      this.getSongsSungToday().then(() => {});
    }
    this.songsSungToday++;
    this.localStorage.setItem('songsSungToday', this.songsSungToday);
  }

  getSungSongs() {
    return this.sungSongs;
  }

  setSungSongs(value: number) {
    this.sungSongs = value;
  }

  async getSongsSungToday() {
    if (!this.songsSungToday) {
      const songsSungToday = await this.localStorage.getItem('songsSungToday');
      this.songsSungToday = songsSungToday ? songsSungToday : 0;
    }
    return this.songsSungToday;
  }
}
