import { Route } from '@angular/router';
import { WebBannerResolver } from 'src/resolvers/web-banner.resolver';

export const masterState: Route = {
  path: '',
  loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
  resolve: {
    webBanner: WebBannerResolver,
  },
};

export const toptrackState: Route = {
  path: 'toptrack',
  redirectTo: '',
};

export const exploraWebState: Route = {
  path: 'explora-web',
  // redirectTo: '',
  loadChildren: () => import('./pages/explora-web/explora-web.module').then(m => m.ExploraWebModule),
};

// export const playlistWebState: Route = {
//   path: 'playlist',
//   // redirectTo: '',
//   loadChildren: () => import('./pages/playlists/playlist.module').then(m => m.PlaylistModule),
// };

// export const artistWebState: Route = {
//   path: 'artist',
//   // redirectTo: '',
//   loadChildren: () => import('./pages/playlists/playlist.module').then(m => m.PlaylistModule),
// };

export const lgproduccionState: Route = {
  path: 'lgproduccion',
  redirectTo: '',
};

export const index2State: Route = {
  path: 'index2',
  redirectTo: '',
};

export const indexHTMLState: Route = {
  path: 'index.html',
  redirectTo: '',
};

export const waitMobile: Route = {
  path: 'wait-mobile',
  loadChildren: () => import('./pages/wait-mobile/wait-mobile.module').then(m => m.WaitMobileModule),
};

export const thanksForPay: Route = {
  path: 'thanks-for-pay',
  loadChildren: () => import('./pages/thanks-for-pay/thanks-for-pay.module').then(m => m.ThanksForPayModule),
};

// export const kantoNotAvailable: Route = {
//   path: 'kanto-not-available',
//   loadChildren: () => import('./pages/kanto-not-available/kanto-not-available.module').then(m => m.KantoNotAvailableModule),
// };

export const userState: Route = {
  path: 'user',
  loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule),
};

export const smsWizardState: Route = {
  path: 'sms-wizard',
  loadChildren: () => import('./pages/sms-wizard/sms-wizard.module').then(m => m.SmsWizardModule),
};

export const castState: Route = {
  path: 'cast',
  loadChildren: () => import('./pages/cast/cast.module').then(m => m.CastModule),
};

export const appRoutes: Route[] = [
  masterState,
  // {
  //   path: 'safetypay',
  //   loadChildren: () => import('./pages/safety-pay-modal/safety-pay-modal.module').then(m => m.SafetyPayModalModule),
  // },
  {
    path: 'subscription',
    loadChildren: () =>
      import('./pages/subscription-score/subscription-score.module').then(m => m.SubscriptionScoreModule),
  },
  {
    path: 'waiting-web-checkout',
    loadChildren: () =>
      import('./pages/waiting-web-checkout/waiting-web-checkout.module').then(m => m.WaitingWebCheckoutModule),
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule),
  },
  {
    path: 'coupons',
    loadChildren: () => import('./pages/coupon/coupon.module').then(m => m.CouponModule),
  },
  lgproduccionState,
  index2State,
  indexHTMLState,
  toptrackState,
  exploraWebState,
  // playlistWebState,
  // artistWebState,
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomeModule),
  },
  {
    path: 'welcome-video',
    loadChildren: () => import('./pages/video-layout/video-layout.module').then(m => m.VideoLayoutModule),
  },
  {
    path: 'welcome-channel',
    loadChildren: () => import('./pages/welcome-channel/welcome-channel.module').then(m => m.WelcomeChannelModule),
  },
  {
    path: 'movistar-home',
    loadChildren: () => import('./pages/movistar-home/movistar-home.module').then(m => m.MovistarHomeModule),
  },
  {
    path: 'kanto-not-available',
    loadChildren: () =>
      import('./pages/kanto-not-available/kanto-not-available.module').then(m => m.KantoNotAvailableModule),
  },
  // {
  //   path: 'welcome-image',
  //   loadChildren: () => import('./pages/welcome-image/welcome-image.module').then(m => m.WelcomeImageModule),
  // },
  castState,
  waitMobile,
  thanksForPay,
];
