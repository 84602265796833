import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from '@providers/local-storage/local-storage';

import { ChromecastService } from '../chromecast/chromecast.service';
import { LocationService } from '../helpers/location';
import { MetricsService } from '../metrics/metrics.service';
import { DeviceKS } from './device-ks';

@Injectable()
export class DevicePlatformInitializer {
  constructor(
    private chromecast: ChromecastService,
    private device: DeviceKS,
    private metrics: MetricsService,
    private location: LocationService,
    private injector: Injector,
    private localStorage: LocalStorage
  ) {}

  load(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (!this.localStorage.ready) {
        await this.localStorage.$isReady.toPromise().then(() => {});
      }
      await this.device
        .init()
        .then(async () => {
          if (this.chromecast.isChromecast()) {
            this.chromecast.init();
          } else if (this.location.getUrlParameter('platform') === 'zeasn') {
            await this.device.setDeviceType('zeasn');
          }
          resolve(this.chromecast.isChromecast());
        })
        .catch(e => {
          reject(e);
        });
    });
  }

  bootstrap(): void {
    this.metrics.abrioApp(this.device.currentDUID);
    if (this.chromecast.isChromecast()) {
      const router = this.injector.get(Router);
      router.navigate(['cast', 'tv-wait']);
    }
  }
}

export const devicePlatformInitializerFactory =
  (service: DevicePlatformInitializer): (() => Promise<any>) =>
  () =>
    service.load();

export const devicePlatformBootstrapFactory =
  (service: DevicePlatformInitializer): (() => void) =>
  () =>
    service.bootstrap();
