import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceKS } from '@providers/device-ks/device-ks';
import { Mxp } from '@providers/mxp/mxp';
import { includes, some } from 'lodash-es';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { CountryService } from '../country/country';
import { LocalStorage } from '../local-storage/local-storage';
import { User } from '../user/user';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  omitTokenPaths = ['password/reset', 'accounts/signin', 'accounts/signup'];
  constructor(
    private mxp: Mxp,
    private country: CountryService,
    private device: DeviceKS,
    private localStorage: LocalStorage,
    private translate: TranslateService,
    public user: User
  ) {}

  mustOmitToken(path: string) {
    return some(this.omitTokenPaths, el => includes(path, el));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.search(environment.api) === 0) {
      // attach tcken ç
      return from(this.localStorage.getItem('tokenUser')).pipe(
        switchMap(tokenUser => this.handleApiRequest(request, next, tokenUser))
      );
    } else {
      return next.handle(request);
    }
  }

  handleApiRequest(
    request: HttpRequest<any>,
    next: HttpHandler,
    localStorageTokenUser?: string
  ): Observable<HttpEvent<any>> {
    let tokenUser: string | undefined;

    if (this.mustOmitToken(request.url)) {
      tokenUser = undefined;
    } else {
      tokenUser = this.user.currentUser ? this.user.currentUser.token : localStorageTokenUser;
    }
    const currentLang = this.translate.currentLang ? this.translate.currentLang.slice(0, 2) : undefined;
    const currentPlatform = request.headers.get('platform') || 'web';
    request = tokenUser
      ? request.clone({
          setHeaders: {
            Authorization: `token ${tokenUser}`,
            language: currentLang,
            country: this.country.currentCountry,
            platform: currentPlatform,
            device: this.device.name || 'web',
            distinctid: this.mxp.distinct_id.value,
          },
        })
      : request.clone({
          setHeaders: {
            language: currentLang,
            country: this.country.currentCountry,
            platform: currentPlatform,
            device: this.device.name || 'web',
            distinctid: this.mxp.distinct_id.value,
          },
        });

    const handler = next.handle(request).pipe(
      catchError((error, caught) => {
        if (error.status === 401 || error.status === 403) {
          // this.store.dispatch(new fromAuth.LogoutAction());
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );

    return handler;
  }
}
