import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KsResponse } from '@interfaces/ks-response.interface';
import { getPictureUrlFromProfile } from '@providers/helpers/helpers';
import { TranslateInitializer } from '@providers/i18n/translate.initializer';
import { nameFromUser } from '@providers/payment-gateways/functions';
import { PendingRequestService } from '@providers/pending-request/pending.request.service';
import { BehaviorSubject, from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, share, switchMap } from 'rxjs/operators';

import {
  AnonymousSessionInterface,
  LoginResponseInterface,
  UserRealTimeInterface,
  UserSessionResponseInterface,
} from '../../interfaces/interfaces';
import { Api } from '../api/api';
import { Config } from '../config/config';
import { CountryService } from '../country/country';
import { DeviceKS } from '../device-ks/device-ks';
import { LocalStorage } from '../local-storage/local-storage';
import { MetricsService } from '../metrics/metrics.service';
import { Notifications } from '../notifications/notifications';
import { Platform } from '../platform/platform';
import { SessionService } from './session.service';
import { UserRealtimeService } from './user-realtime';
/**
 * Most apps have the concept of a User. This is a simple provider
 * with stubs for login/signup/etc.
 *
 * This User provider makes calls to our API at the `login` and `signup` endpoints.
 *
 * By default, it expects `login` and `signup` to return a JSON object of the shape:
 *
 * ```json
 * {
 *   status: 'success',
 *   user: {
 *     // User fields your app needs, like 'id', 'name', 'email', etc.
 *   }
 * }
 * ```
 *
 * If the `status` field is not `success`, then an error is detected and returned.
 */
@Injectable()
export class User {
  _user: UserRealTimeInterface;
  public user$: BehaviorSubject<UserRealTimeInterface> = new BehaviorSubject(undefined);
  // sessionDeleteSub: Subscription;
  localSession: any;
  readyState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  userLoginOK: Subject<any> = new Subject();
  keepAliveInterval;
  sessionError: boolean;
  messages: any;
  goRootSamsung: Subject<any> = new Subject();
  constructor(
    private country: CountryService,
    private translateInitial: TranslateInitializer,
    public config: Config,
    public device: DeviceKS,
    public http: HttpClient,
    public api: Api,
    public notifications: Notifications,
    private metrics: MetricsService,
    private localStorage: LocalStorage,
    private platform: Platform,
    private pendingRequestService: PendingRequestService,
    private userRealtime: UserRealtimeService,
    private sessionService: SessionService
  ) {}

  doReload() {
    document.write('');
    location.reload();
  }
  onRetry(e: KeyboardEvent) {
    // eslint-disable-next-line import/no-deprecated
    if (e.keyCode === 13) {
      (window as any).doReload();
      return false;
    }
    document.getElementById('retry-btn').focus();
    return false;
  }

  setViewPayment() {
    this.api
      .post('send/notification/', {
        token: this.getLocalSession().token,
      })
      .subscribe(() => {});
  }
  // Returns true if user is logged in
  get authenticated(): boolean {
    return this._user !== null && this._user !== undefined;
  }

  // Returns current user
  get currentUser(): UserRealTimeInterface {
    return this.authenticated ? this._user : null;
  }

  get premium() {
    return this.currentUser && this.currentUser.premium;
  }

  get isFreemium() {
    return this.authenticated && !this.premium;
  }

  get getUserPhoto() {
    const user = this._user;
    let urlPhoto = './images/app/iconos/profile-panel.png';
    if (user) {
      urlPhoto = user.img
        ? user.img
        : user.fb_id
        ? 'https://graph.facebook.com/' + user.fb_id + '/picture?redirect=true&type=large&height=200&width=200'
        : './images/app/iconos/profile-panel.png';
    }
    return urlPhoto;
  }
  // Returns current user UID
  /*get currentUserId(): string {
      return this.authenticated ? this._user.uid : '';
    } */
  /**
   * Send a POST request to our login endpoint with the data
   * the user entered on the form.
   */
  login(accountInfo: any): Observable<UserSessionResponseInterface> {
    const seq = this.api
      .post('accounts/signin', accountInfo)
      .pipe(
        switchMap((res: LoginResponseInterface) => {
          // If the API returned a successful response, mark the user as logged in
          if (res.success === true) {
            res.data.created = res.created;
            return of(res.data);
          } else {
            return throwError(res.message ? res : { message: 'Ocurrio un error inesperado' });
          }
        }),
        catchError(this.handleError.bind(this))
      )
      .pipe(
        switchMap((session: UserSessionResponseInterface) => {
          if (!this.authenticated) {
            return from(this._loggedIn(session)).pipe(map(() => session));
          } else {
            return of(session);
          }
        }),
        share()
      );

    seq.subscribe(
      async session => {
        this.metrics.identify(session, session.id);
      },
      () => {}
    );

    return seq;
  }

  loginWithCode(code: number): Observable<UserSessionResponseInterface> {
    const seq = this.api
      .post('user/session/', { codigo: code })
      .pipe(
        switchMap((response: KsResponse<UserSessionResponseInterface>) => {
          if (response.success) {
            response.data.created = response['created'];
            return of(response.data);
          } else {
            return throwError(response);
          }
        }),
        catchError(this.handleError.bind(this))
      )
      .pipe(
        switchMap((session: UserSessionResponseInterface) => {
          if (!this.authenticated) {
            return from(this._loggedIn(session)).pipe(map(() => session));
          } else {
            return of(session);
          }
        }),
        share()
      );

    seq.subscribe(
      (session: UserSessionResponseInterface) => {
        this.metrics.identify(session, session.id);
      },
      () => {}
    );
    return seq;
  }

  /**
   * Send a POST request to our signup endpoint with the data
   * the user entered on the form.
   */
  signup(accountInfo: any): Observable<UserSessionResponseInterface> {
    // flag for pending request
    this.pendingRequestService.pendingSignupRequest$.next(true);
    const seq = this.api
      .post('accounts/signup', accountInfo)
      .pipe(
        switchMap((res: LoginResponseInterface) => {
          // If the API returned a successful response, mark the user as logged in
          if (res.success === true) {
            res.data.created = res.created;
            return of(res.data);
          } else {
            return throwError(res.message ? res : { message: 'Ocurrio un error inesperado' });
          }
        }),
        catchError(this.handleError.bind(this))
      )
      .pipe(
        switchMap((session: UserSessionResponseInterface) => {
          if (!this.authenticated) {
            return from(this._loggedIn(session)).pipe(map(() => session));
          } else {
            return of(session);
          }
        }),
        share()
      );

    seq.subscribe(
      async (session: UserSessionResponseInterface) => {
        this.pendingRequestService.pendingSignupRequest$.next(false);
        this.metrics.identify(session, session.id);
      },
      err => {
        this.pendingRequestService.pendingSignupRequest$.next(false);
        console.error('ERROR', err);
      }
    );

    return seq;
  }

  recovery(email) {
    const seq = this.api
      .post('user/password/reset/', email)
      .pipe(
        map(
          res =>
            // If the API returned a successful response, mark the user as logged in
            res
        )
      )
      .pipe(share());

    return seq;
  }

  updateUserProperty(property: string, data: any) {
    this._user[property] = data;
    // this.user$.next(this._user);
  }

  updateToPremium() {
    if (this.authenticated) {
      this._user.premium = true;
      this.user$.next(this._user);
      this.metrics.identify(this._user, this._user.id);
      this.metrics.register({ Membership: 'Premium' });
    }
  }

  registerInitialDataOnMetrics() {
    this.device.registerDeviceDataOnMetrics();
    this.sessionService.registerSessionDataOnMetrics();
    this.country.registerCountryDataOnMetrics();
    this.translateInitial.registerLanguageOnMetrics();
  }

  /**
   * Log the user out, which forgets the session
   */
  async logout() {
    const oldToken = await this.localStorage.getItem('tokenTv');
    return this.sessionService
      .logoutSession(this.device.currentDUID, oldToken)
      .toPromise()
      .then(session => {
        this.clearUserData();
        this.localSession = session;
      })
      .catch(() => {
        this.clearUserData();
        location.reload();
      });
  }

  clearUserData() {
    this._user = null;
    this.localSession = null;
    this.localStorage.removeItem('user_id');
    this.localStorage.removeItem('tokenUser');
    this.localStorage.removeItem('selectedGenres');
    this.localStorage.removeItem('country');
    this.user$.next(undefined);
    this.metrics.reset();
    this._user = null;
    this.registerInitialDataOnMetrics();
  }

  async initUser(session: UserSessionResponseInterface | AnonymousSessionInterface, whereSession = 'init') {
    // console.log('INIT USER', this.device, session);
    if (!session.is_anonymous) {
      session = session as UserSessionResponseInterface;
      this.localSession = session.session_tv;

      // posible error app no abre
      if (!session.first_name) {
        session.first_name = nameFromUser(session);
      }

      this.metrics.identify(session, session.id);

      if (session.session_tv.is_register && whereSession !== 'init') {
        this.metrics.sendEvent('createAccount', {
          idUser: session.id,
          Premium: !!session.premium,
          Email: session.email,
          'Where Session': whereSession,
        });
      }

      this.metrics.sendEvent('openSesion', {
        idUser: session.id,
        Premium: !!session.premium,
        Email: session.email,
        'Where Session': whereSession,
      });
      await this._loggedIn(session);
    } else {
      session = session as AnonymousSessionInterface;
      this.localSession = session;
      this._user = null;
    }
    this.readyState.next(true);
  }
  async reinitSession() {
    this.sessionService.getNewSession(this.device.currentDUID).subscribe(
      session => {
        this.initUser(session as UserSessionResponseInterface);
      },
      catchError(err => throwError(err))
    );
  }

  async updateUserWithRealTimeData(data: UserSessionResponseInterface): Promise<UserRealTimeInterface> {
    return this.userRealtime.initRealTimeUser(data);
  }
  /**
   * Process a login/signup response to store user data
   */
  async _loggedIn(session: UserSessionResponseInterface) {
    const userRealtimeData = await this.updateUserWithRealTimeData(session);

    this._user = { ...session, ...userRealtimeData };
    // TODO:  set on false
    // this.playlist.loadFromResolve = false;
    const sessionCountry = session.country;
    if (sessionCountry && this.country.currentCountry !== sessionCountry) {
      this.country.setCountry(sessionCountry);
    }
    this._user.img = this.patchImage(this._user);
    if (this._user.session_tv) {
      this.localSession = this._user.session_tv;
      // this.localStorage.setItem('session', JSON.stringify(this._user.session_tv));
      await this.localStorage.setItem('tokenTv', this._user.session_tv.token);
    }
    await this.localStorage.setItem('tokenUser', this._user.token);
    await this.localStorage.setItem('user_id', this._user.id);
    this.metrics.identify(this._user, this._user.id);
    this.metrics.register({
      'Kanto Email': this._user.email,
      'Kanto User Country': this._user.country,
      Membership: this._user.premium ? 'Premium' : 'Free',
    });
    this.user$.next(this._user);
    if (this.platform.isBrowser()) {
      this.callKeepalive(this.sessionService.getToken());
    }
  }

  patchImage(user: UserSessionResponseInterface) {
    return getPictureUrlFromProfile(user);
  }
  requestKeepalive(token: string) {
    return this.api.post('sessions/keepalive', { token });
  }

  callKeepalive(token: string) {
    const time = 1800000;
    // const time = 10000;
    const requestCall = () => {
      const requestCallApi = this.requestKeepalive(token);
      const actions = () => {
        if (this.device && this.device.name === 'tizen') {
          this.logout().then(() => {
            this.goRootSamsung.next(true);
          });
        } else {
          location.reload();
        }
      };
      requestCallApi.subscribe(
        response => {
          if (response.success === false) {
            actions();
          }
        },
        () => {
          actions();
        }
      );
    };

    clearInterval(this.keepAliveInterval);
    this.keepAliveInterval = setInterval(requestCall, time);
  }

  async selectPlanForPurchase(plan) {
    return this.sessionService.updateSession({
      selectedPlanForPurchase: plan,
    });
  }

  getLocalSession() {
    return this.localSession;
  }

  /**
   * @deprecated
   * Use SessionService.getQR instead
   */
  getSessionQR() {
    if (!this._user && !this.localSession) {
      // this.router.navigate(['/login']);
      return null;
    }
    return this.sessionService.getQR();
  }

  onReady(callBack: () => any) {
    return this.readyState.subscribe(ready => {
      if (ready) {
        callBack();
      }
    });
  }
  setDataPaymentUser(data) {
    this.currentUser.payment = data;
    this.currentUser.payment['end_subscriptionDate'] = new Date(this.currentUser.payment.end_subscription);
    this.currentUser.payment['init_subscriptionDate'] = new Date(this.currentUser.payment.init_subscription);
  }

  setPlans(plans) {
    if (this._user) {
      this._user.plans = plans;
    }
  }

  changePass(data) {
    return this.api.post('accounts/' + this.currentUser.id + '/password/change', data);
  }
  sendEmailToReset(data) {
    return this.api.post('password/reset', data);
  }
  getInfoComplety() {
    return this.api.get('user/info/web');
  }

  getInfoProfile(): Observable<UserSessionResponseInterface> {
    return this.api.get('user_profile').pipe(
      switchMap(res => {
        if (res.success) {
          return of(res.data as UserSessionResponseInterface);
        } else {
          return throwError(res.message);
        }
      })
    );
  }

  updateUserData() {
    const req = this.getInfoComplety().pipe(
      switchMap((res: KsResponse<any>) => {
        if (res.success) {
          this.setDataPaymentUser(res.data.payment);
          return of(res.data);
        } else {
          return throwError(res);
        }
      }),
      share()
    );
    return req;
  }

  handleError(err) {
    const error = err.error || err || {};
    error.message = error.message || 'Error desconocido';
    return throwError(error);
  }
}
