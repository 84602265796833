import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { keyBy } from 'lodash-es';
import { from, Observable } from 'rxjs';
import { map, mergeMap, switchMap, take, tap, toArray } from 'rxjs/operators';

import { CountryResolver } from '../../resolvers/country.resolver';
import { LocationService } from '../helpers/location';
import { LocalStorage } from '../local-storage/local-storage';
import { MetricsService } from '../metrics/metrics.service';
import { ABTestingInterface } from './ab-testing.interface';

@Injectable()
export class ABTestingService {
  currentActiveABTestings: { [testingId: string]: ABTestingInterface } = {};

  constructor(
    private http: HttpClient,
    private location: LocationService,
    private countryResolver: CountryResolver,
    private localStorage: LocalStorage,
    private metrics: MetricsService
  ) {}

  isActiveTest(abTestingId: string): boolean {
    return !!this.currentActiveABTestings[abTestingId];
  }

  iAmCase(abTestingId: string, abCase: string): boolean {
    if (!this.isActiveTest(abTestingId)) {
      return false;
    }
    return this.currentActiveABTestings[abTestingId].currentCase === abCase;
  }

  getCaseWinner(abTestingId: string, country: string): boolean | string {
    if (!this.currentActiveABTestings[abTestingId].winner) {
      return false;
    }
    return (
      this.currentActiveABTestings[abTestingId].winner[country] || this.currentActiveABTestings[abTestingId].winner.all
    );
  }

  load() {
    const currentABTest = this.location.getUrlParameter('currentABTest');
    const currentABTestCase = this.location.getUrlParameter('currentABTestCase');

    if (currentABTest && currentABTestCase) {
      this.localStorage.setItem(`AB${currentABTest}`, currentABTestCase);
      this.localStorage.setItem(`AB${currentABTest}TestingID`, `${currentABTest}-${currentABTestCase}NN`);
    }
    // TODO: Determinar el momento exacto para resolver esto
    return this.countryResolver.resolve().then(country =>
      this.getActiveABTestings(country)
        .pipe(
          switchMap(abTestings =>
            from(abTestings).pipe(
              mergeMap(ab => this.getABTestingID(ab._id, country)),
              toArray()
            )
          )
        )
        .toPromise()
    );
  }

  getActiveABTestings(country: string): Observable<ABTestingInterface[]> {
    let test = 'false';
    if (environment.debug) {
      test = 'true';
    }
    return this.http
      .get(environment.firebase.functionsURL + '/getActiveABTestingForMe', { params: { country, test } })
      .pipe(
        tap((response: any) => {
          this.currentActiveABTestings = keyBy(response, '_id');
          // unregistrar inactivos
          this.unRegisterABTesting('omitSelectGenres');
          this.unRegisterABTesting('twoStepRegister');
          this.unRegisterABTesting('newCheckout');
          this.unRegisterABTesting('testTrafic');
        }),
        take(1)
      );
  }

  async getABTestingID(abTestingId: string, country: string): Promise<ABTestingInterface> {
    if (this.existCurrentABTesting(abTestingId)) {
      const currentABTesting = {
        _id: abTestingId,
        currentCase: await this.localStorage.getItem(`AB${abTestingId}`),
        testingID: await this.localStorage.getItem(`AB${abTestingId}TestingID`),
      };

      this.checkWinner(currentABTesting, country);

      this.currentActiveABTestings[abTestingId] = currentABTesting;
      return Promise.resolve(currentABTesting);
    }
    let test = 'false';
    if (environment.debug) {
      test = 'true';
    }
    return this.http
      .get(environment.firebase.functionsURL + '/getABTestingCase', { params: { testID: abTestingId, test } })
      .pipe(
        tap((response: ABTestingInterface) => {
          this.setLocalAbTesting(response);
        }),
        map((response: ABTestingInterface) => {
          this.checkWinner(response, country);
          this.currentActiveABTestings[abTestingId] = response;
          return response;
        }),
        take(1)
      )
      .toPromise();
  }

  checkWinner(currentABTesting: ABTestingInterface, country: string) {
    const winner = this.getCaseWinner(currentABTesting._id, country);
    if (winner) {
      currentABTesting.currentCase = winner as string;
    }
  }

  setLocalAbTesting(abTesting: ABTestingInterface) {
    this.currentActiveABTestings[abTesting._id] = abTesting;
    this.localStorage.setItem(`AB${abTesting._id}`, abTesting.currentCase);
    this.localStorage.setItem(`AB${abTesting._id}TestingID`, abTesting.testingID);
    this.metrics.register({
      [`AB${abTesting._id}`]: abTesting.currentCase,
      [`AB${abTesting._id}TestingID`]: abTesting.testingID,
    });
  }

  existCurrentABTesting(abTestingId: string) {
    return this.localStorage.getItem(`AB${abTestingId}`) && this.localStorage.getItem(`AB${abTestingId}TestingID`);
  }

  unRegisterABTesting(abTestingId: string) {
    this.metrics.unregister(`AB${abTestingId}`);
    this.metrics.unregister(`AB${abTestingId}TestingID`);
  }
}

export const abTestingFactory =
  (service: ABTestingService): (() => Promise<any>) =>
  () =>
    service.load();
