import { CountryService, RootScopeAdapter } from '@providers/providers';

import { PlayerService } from './player.service';
import { TimeNotifier } from './time-notifier';

export class ProgressManager {
  private timeM5: boolean;
  private timeM10: boolean;

  private progressAux = 0;
  private bufferAux = 0;

  private timeNotifier = new TimeNotifier();

  get currentClip() {
    return this.playerService.getCurrentClip();
  }

  constructor(
    private playerService: PlayerService,
    private country: CountryService,
    private rootScopeAdapter: RootScopeAdapter
  ) {}

  resetTimeNotifier() {
    this.timeNotifier.reset();
  }

  setProgressAux(progress: number) {
    this.progressAux = progress;
  }

  setBufferAux(buffer: number) {
    this.bufferAux = buffer;
  }

  onProgressActions = (e: Event) => {
    // Actualiza la hora actual y redondea la hora al número entero más cercano.
    const time = Math.floor(this.playerService.getDomPlayer().currentTime());
    this.currentClip.currentTime = time;
    this.currentClip.time = time;

    // Acciones a realizar en función del tiempo actual.
    this.performTimeDependentActions(time);

    // Actualiza el progreso y el búfer.
    const progress = Math.floor((time / this.currentClip.duration) * 1280);
    const buffer = Math.floor((this.currentClip.buffer / this.currentClip.duration) * 100);
    this.currentClip.currentBufferPercent = buffer || 0;

    // Emitir eventos.
    this.emitProgressAndBufferEvents(progress, buffer);
  };

  private performTimeDependentActions(time: number) {
    if (time <= 10) {
      this.playerService.onTimeLess10.next({ time });
    }

    const halfOfSOng = Math.floor(this.currentClip.duration / 2);
    const fourFifthsOfSong = Math.floor((this.currentClip.duration * 4) / 5);
    const tenSecondsBeforeEnd = this.currentClip.duration - 10;

    // Se mapean las condiciones de tiempo en un objeto clave-valor.
    const timeConditions: {
      [key: number]: () => boolean;
    } = {
      1: () =>
        this.country.currentCountry !== 'CO' && this.country.currentCountry !== 'MX' && !this.timeNotifier.timeM1,
      5: () => !this.timeM5,
      8: () => this.rootScopeAdapter.activeFastPlay && !this.timeNotifier.time30,
      30: () => !this.timeNotifier.time30,
      [halfOfSOng]: () => true,
      [fourFifthsOfSong]: () => !this.timeNotifier.timeM80,
      [tenSecondsBeforeEnd]: () => !this.timeNotifier.timeM10,
    };

    // Ejecuta la acción correspondiente a la hora actual si hay alguna.
    if (timeConditions[time] && timeConditions[time]()) {
      switch (time) {
        case 1:
          this.timeNotifier.timeM1 = true;
          break;
        case 5:
          this.timeM5 = true;
          this.playerService.onTimeM5.next();
          break;
        case 8:
          this.timeNotifier.time30 = true;
          this.playerService.onTime30.next(this.currentClip);
          this.playerService.getDomPlayer().currentTime(this.currentClip.duration - 10);
          break;
        case 30:
          this.timeNotifier.time30 = true;
          this.playerService.onTime30.next(this.currentClip);
          break;
        case halfOfSOng:
          this.playerService.setNextToDel(this.currentClip);
          break;
        case fourFifthsOfSong:
          this.timeNotifier.timeM80 = true;
          this.playerService.onTimeM80.next(this.currentClip);
          break;
        case tenSecondsBeforeEnd:
          this.timeNotifier.timeM10 = true;
          this.playerService.onTimeM10.next(this.currentClip);
          break;
      }
    }
  }

  private emitProgressAndBufferEvents(progress: number, buffer: number) {
    this.playerService.onProgress.next(this.currentClip);

    if (Math.round(this.currentClip.buffer) >= Math.round(this.currentClip.duration)) {
      this.playerService.onFullyLoadedVideo.next();
    }

    if (progress !== this.progressAux && progress > this.progressAux) {
      this.progressAux = progress;
      this.playerService.onTimeSeconds.next(this.currentClip);
      this.playerService.playing$.next(true);
    }
  }
}
