import {
  CollectionReference,
  DocumentChange,
  DocumentReference,
  DocumentSnapshot,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from '@providers/firebase/firestore.functions';
import { mapValues } from 'lodash-es';
import { fromEventPattern, Observable, OperatorFunction } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export type IdAble<T> = {
  id: any;
} & T;

type DocumentChangeCallback<T> = (documentChange: DocumentChange<T>, data: T) => void;

export const validateSnapshot: <T>(snap: DocumentSnapshot<T>) => T = <T>(snap: DocumentSnapshot<T>) => {
  if (snap.exists) {
    return snap.data();
  }
  return null;
};

export const valueChanges: <T>() => (source: Observable<any>) => Observable<T> =
  <T>() =>
  (source: Observable<any>) =>
    source.pipe(map(validateSnapshot)) as Observable<T>;

export const documentFromOnSnapshot: <T>(
  docRef: DocumentReference<T>,
  metadataChanges?: boolean
) => Observable<DocumentSnapshot<T>> = <T>(docRef: DocumentReference<any>, metadataChanges: boolean = false) =>
  fromEventPattern<DocumentSnapshot<T>>(
    handler => onSnapshot<T>(docRef, { includeMetadataChanges: metadataChanges }, handler),
    (handler, unsubscribe) => {
      unsubscribe();
    }
  );

export const collectionFromOnSnapshot: <T>(
  collection: CollectionReference<T>,
  metadataChanges?: boolean
) => Observable<QuerySnapshot<T>> = <T>(collection: CollectionReference<T>, metadataChanges: boolean = false) =>
  fromEventPattern(
    handler => onSnapshot<T>(query(collection), { includeMetadataChanges: metadataChanges }, handler),
    (handler, unsubscribe) => {
      unsubscribe();
    }
  );

const filterDocChangesByType: <T>(
  types: ('added' | 'modified' | 'removed')[]
) => (querySnapshot: QuerySnapshot<T>) => DocumentChange<T>[] =
  <T>(types: ('added' | 'modified' | 'removed')[]) =>
  (querySnapshot: QuerySnapshot<T>) => {
    const docChanges = querySnapshot.docChanges() as DocumentChange<T>[];
    return docChanges.filter(change => types.includes(change.type));
  };

const convertToIdAbleData = <T>(dataDoc: QueryDocumentSnapshot<IdAble<T>>) => {
  const data = dataDoc.data();
  const id = dataDoc.id;
  data.id = id;
  return data;
};

export const mapCollection: <T>(
  documentChangeCallBack?: DocumentChangeCallback<T>
) => (documentChanges: DocumentChange<IdAble<T>>[]) => IdAble<T>[] =
  <T>(documentChangeCallBack?: DocumentChangeCallback<T>) =>
  (actions: DocumentChange<IdAble<T>>[]) =>
    actions.map((a: DocumentChange<IdAble<T>>) => {
      const queryDoc = a.doc;
      const data = convertToIdAbleData(queryDoc);
      if (documentChangeCallBack) {
        documentChangeCallBack(a, data);
      }

      return data;
    });

export const mapCollectionFromQuerySnapshot: <T>(querySnapshot: QuerySnapshot<IdAble<T>>) => IdAble<T>[] = <T>(
  querySnapshot: QuerySnapshot<IdAble<T>>
) => {
  const elements: IdAble<T>[] = [];
  querySnapshot.forEach(doc => {
    const data = convertToIdAbleData(doc as QueryDocumentSnapshot<IdAble<T>>);
    elements.push(data);
  });
  return elements;
};

export const mapFilteredDocChanges: <T>(
  types: ('added' | 'modified' | 'removed')[]
) => OperatorFunction<QuerySnapshot<T>, DocumentChange<T>[]> =
  (types: ('added' | 'modified' | 'removed')[]) => source =>
    source.pipe(
      filter(querySnapshot => querySnapshot.docChanges().length > 0),
      map(filterDocChangesByType(types))
    );

export const parseFirestoreResponse = (response: firebase.default.firestore.DocumentData) =>
  mapValues(response.fields, value => {
    if (value.mapValue) {
      return parseFirestoreResponse(value.mapValue);
    } else {
      return value.stringValue || value.integerValue;
    }
  });
