import { Basic } from './device-platform';
import { IDevicePlatform } from './device-platform.interface';

export class WebApp extends Basic implements IDevicePlatform {
  name = 'webapp';
  type = 'webapp';

  deviceData: {
    modelName: string;
  };

  constructor() {
    super();
    this.deviceData = {
      modelName: 'Web App',
    };

    this.deviceData = { ...this.deviceData, modelName: 'Web App' };
  }
}
