import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../environments/environment';
import { getDifferenceTime, intervalTime, objectWithoutAttrEmpty } from '../../providers/helpers/helpers';
import { DeviceKS } from '../device-ks/device-ks';
import { LocalStorage } from '../local-storage/local-storage';
import { Platform } from '../platform/platform';
import { User } from '../user/user';

@Injectable()
export class Mxp {
  mixpanel: any;
  isReady: boolean;
  distinct_id: BehaviorSubject<string> = new BehaviorSubject(null);
  mixpanelLocalStorage: any = {};
  idProjectMixpanel = environment.mixpanel.token;
  versionApp = environment.version;
  mergeWithLogin = false;
  readyInteraction;

  constructor(private platform: Platform, private localStorage: LocalStorage) {
    if (this.platform.isBrowser()) {
      // Client only code.
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const mixpanel = require('mixpanel-browser');
      mixpanel.init(this.idProjectMixpanel, {
        persistence: 'localStorage',
        loaded: res => {
          this.distinct_id.next(mixpanel.get_distinct_id().replace(/-/g, ''));
          this.isReady = true;
          this.mixpanel = mixpanel;
          (window as any).mixpanel = mixpanel;
          mixpanel.track('mixpanelReady');
        },
      });
      (window as any).versionApp = this.versionApp;
      (window as any).buildNumber = environment.buildNumber;
      console.log('versionApp', this.versionApp);
    }
  }

  // --
  async emitAbrioApp(serialNumber?: string) {
    const abrioApp = {};

    const tvDUIDPromise = this.localStorage.getItem('TVDUID');
    const tokenTvPromise = this.localStorage.getItem('tokenTv');
    const userIdPromise = this.localStorage.getItem('user_id');
    const tokenUserPromise = this.localStorage.getItem('tokenUser');
    const countryPromise = this.localStorage.getItem('country');
    const emailUsedPromise = this.localStorage.getItem('emailUsed');

    const [tvDUID, tokenTv, userId, tokenUser, country, emailUsed] = await Promise.all([
      tvDUIDPromise,
      tokenTvPromise,
      userIdPromise,
      tokenUserPromise,
      countryPromise,
      emailUsedPromise,
    ]);

    abrioApp['Current Local Storage'] = {
      tvDUID,
      tokenTv,
      user_id: userId,
      tokenUser,
      country,
      emailUsed,
    };

    abrioApp['Current Cookies'] = document.cookie;

    this.sendEvent('abrioApp', abrioApp);
  }
  sendEvent(name: string, data?: unknown) {
    // console.log(name, data);
    if (this.isReady) {
      if (name) {
        this.mixpanel.track(name, data);
      } else {
        console.error('NO SE ENVIO NOMBRE DE EVENTO');
      }
    }
  }

  register(data: unknown, user_id?: number | string) {
    if (this.isReady) {
      let dataToSend: any;
      try {
        dataToSend = objectWithoutAttrEmpty(data);
      } catch (e) {
        dataToSend = data;
      }
      this.mixpanel.register(dataToSend);
    }
  }

  identify(data, userId) {
    // const dataSend = { ...data, ...{ $email: data.email } };
    // console.log(dataSend, 'people.set identify 2');
    // this.mixpanel.people.set();
    const dataSend = {
      ...data,
      ...{ $name: data.first_name || data['First Name'] },
      ...{ $email: data.email || data.Email || data['Kanto Email'] },
    };

    delete dataSend.Email;

    this.peopleSet(dataSend);
    if (userId) {
      this.mixpanel.identify(userId);
    } else {
      console.error('no se envio userId');
    }
  }

  peopleSet(data) {
    this.mixpanel.people.set(data);
  }

  peopleIncrement(prop, by?) {
    by = by === undefined ? 1 : by;
    this.mixpanel.people.increment(prop, by);
  }

  resetMxp() {
    this.mixpanel.reset();
  }
  unregister(data: string) {
    if (this.isReady) {
      this.mixpanel.unregister(data);
    }
  }

  /**
   * @deprecated
   **/
  setParametersDevices(deviceKs: DeviceKS, user: User) {
    deviceKs.setDevice();
    return this.loadParameters(deviceKs, user);
  }
  loadParameters(deviceKs: DeviceKS, userService: User) {
    if (!this.mergeWithLogin) {
      this.doMergeWithLogin(userService);
    }
  }

  /**
   * @deprecated
   **/
  doMergeWithLogin(userService: User) {
    const sub = userService.onReady(() => {
      if (userService.authenticated) {
        let data;
        const user = userService.currentUser;
        if (user) {
          data = {
            idUser: user.id,
            Email: user.email,
            $email: user.email,
            Premium: user.premium,
            fullName: user.first_name + ' ' + user.last_name,
          };

          // this.mixpanel.people.set(data);
          // this.mixpanel.identify(user.id);
          this.identify(data, user.id);
        }
        this.mergeWithLogin = true;
        this.register(data);
        // FS.setUserVars(data);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        if (sub && !sub.closed) {
          sub.unsubscribe();
        }
      }
    });
  }

  timeLoadApp(country: string) {
    try {
      this.onReadyInteraction(country);
      this.register({ timeLoadApp: getDifferenceTime((window as any).timeInitApp, new Date()) + ' seg' });
      this.register(this.readyInteraction);
    } catch (e) {}
  }

  onReadyInteraction(country) {
    const tiempoInicialCargaApp = parseInt(localStorage.getItem('tiempoInicialCargaApp'), 10);

    this.readyInteraction.tiempoCarga = getDifferenceTime(new Date(), new Date(tiempoInicialCargaApp));
    this.readyInteraction.countryData = country;
    this.readyInteraction['intervalo-carga-app'] = intervalTime(this.readyInteraction.tiempoCarga) + ' segundos';
  }
}
