import { DOCUMENT, Location } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';

import { Platform } from '../platform/platform';

@Injectable()
export class LocationService {
  doc;
  host;
  constructor(private injector: Injector, private location: Location, private platform: Platform) {
    this.doc = this.injector.get(DOCUMENT);
    this.host = this.platform.isServer()
      ? this.injector.get(REQUEST).headers['host']
      : this.doc.location.protocol + '//' + this.doc.location.hostname;
  }

  get currentUrl(): string {
    return this.host + this.location.path();
  }

  get currentPath(): string {
    return this.location.path();
  }

  getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(this.currentUrl);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  redirect(url: string) {
    if (this.platform.isServer()) {
      const response = this.injector.get(RESPONSE);
      response.redirect(url);
      return;
    }
    this.doc.location.href = url;
  }
}
