import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { IBanner } from '@interfaces/banners.interface';
import { CountryService } from '@providers/providers';
import { WebBannerService } from '@providers/web-banner/web-banner.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WebBannerResolver implements Resolve<IBanner | undefined> {
  constructor(private webBannerService: WebBannerService, private country: CountryService) {}

  resolve(): Observable<IBanner | undefined> | Promise<IBanner | undefined> | IBanner | undefined {
    return this.webBannerService.getBannerByCountryGroup().then(data => data);
  }
}
