import { Injectable } from '@angular/core';
import { FirebaseApp } from '@angular/fire';
import { IBanner, ICountriesGroups } from '@interfaces/banners.interface';
import { CountryService } from '@providers/country/country';
import { LocationService } from '@providers/providers';
import { from, Subscription } from 'rxjs';
import { switchMap, take, timeout } from 'rxjs/operators';

@Injectable()
export class WebBannerService {
  private disabled = true; //change it manually if you want to active the web banners service functionality.
  private _countrySub!: Subscription;
  private _banners?: IBanner;
  //this must to be in ISO standar.
  private _validCountries: string[] = ['PE', 'HN', 'GT', 'CL', 'SV', 'NI', 'CR', 'PA', 'MX', 'BR'];
  private _lastCountry?: string;
  private _lastCountryGroup: 'br' | 'mx' | 'rol' | 'invalid' = 'invalid';
  private _countriesGroups: ICountriesGroups = {
    //if you add another valid country, add this to a group too if is necesary.
    br: ['BR'],
    mx: ['MX'],
    rol: ['PE', 'HN', 'GT', 'CL', 'SV', 'NI', 'CR', 'PA'],
  };

  constructor(private country: CountryService, private fireBaseApp: FirebaseApp, private location: LocationService) {}

  get currentCountry(): string {
    return this.country.currentCountry;
  }

  subscribeToRefetchBannerWhenCountryChange() {
    //this.unsubscribeToRefetchBannerWhenCountryChange();
    return this.country.country.pipe(switchMap(country => this.getBannerByCountryGroup()));
  }

  unsubscribeToRefetchBannerWhenCountryChange() {
    if (this._countrySub) {
      this._countrySub.unsubscribe();
    }
  }

  getBannerByCountryGroup(): Promise<IBanner | undefined> {
    if (this.disabled) {
      this._lastCountryGroup = 'invalid';
      Promise.resolve(this._banners);
    }

    if (this.isValidBannerCountry()) {
      if (this._countriesGroups.br.includes(this.currentCountry)) {
        return this.optimizedGetImageFromFirebase('br');
      }
      if (this._countriesGroups.mx.includes(this.currentCountry)) {
        return this.optimizedGetImageFromFirebase('mx');
      }
      if (this._countriesGroups.rol.includes(this.currentCountry)) {
        return this.optimizedGetImageFromFirebase('rol');
      }
      this._lastCountryGroup = 'invalid';
      return Promise.resolve(this._banners);
    }
    this._lastCountryGroup = 'invalid';
    return Promise.resolve(this._banners);
  }

  isValidBannerCountry(): boolean {
    if (this.disabled) {
      return false;
    }

    const index = this._validCountries.findIndex(arrayCountry => arrayCountry === this.currentCountry);

    if (index === -1) {
      return false;
    } else {
      return true;
    }
  }

  excludeRoutes(extendExcludes: string[] = []): boolean {
    const currentPath = this.location.currentPath.split('/');
    const excludes = [...extendExcludes];
    if (currentPath.length > 1) {
      if (excludes.includes(currentPath[1])) {
        return true;
      }
      return false;
    }
    return false;
  }

  private getCurrentCountryGroup(): 'mx' | 'br' | 'rol' | 'invalid' {
    if (this.disabled) {
      return 'invalid';
    }

    if (this._countriesGroups.br.includes(this.currentCountry)) {
      return 'br';
    }
    if (this._countriesGroups.mx.includes(this.currentCountry)) {
      return 'mx';
    }
    if (this._countriesGroups.rol.includes(this.currentCountry)) {
      return 'rol';
    }
    return 'invalid';
  }

  private UrlChanger(banners: IBanner | undefined, country: string): IBanner | undefined {
    //this must to recieve a URL with this format https://exampleUrl.com/_COUNTRY_/anothersUrlThings
    if (banners) {
      const bannersChanged: IBanner = { ...banners };

      for (const property in bannersChanged) {
        if (
          property !== 'isDefault' &&
          bannersChanged[property].desktop.url.includes('_COUNTRY_') &&
          bannersChanged[property].mobile.url.includes('_COUNTRY_')
        ) {
          bannersChanged[property].desktop.url = bannersChanged[property].desktop.url.replace(
            '_COUNTRY_',
            country.toLowerCase()
          );
          bannersChanged[property].mobile.url = bannersChanged[property].mobile.url.replace(
            '_COUNTRY_',
            country.toLowerCase()
          );
        }
      }
      return bannersChanged;
    }
    return banners;
  }

  private optimizedGetImageFromFirebase(bannerGroup: string) {
    if (this.disabled) {
      this._lastCountry = 'invalid';
      this._lastCountryGroup = 'invalid';
      return Promise.resolve(undefined);
    }

    if (!this._banners) {
      this._lastCountry = this.currentCountry;
      this._lastCountryGroup = this.getCurrentCountryGroup();
      return this.getImageFromFirebase(bannerGroup);
    }

    if (
      (!this._lastCountry || this.currentCountry !== this._lastCountry) &&
      (!this._lastCountryGroup || this.getCurrentCountryGroup() !== this._lastCountryGroup)
    ) {
      this._lastCountry = this.currentCountry;
      this._lastCountryGroup = this.getCurrentCountryGroup();
      return this.getImageFromFirebase(bannerGroup);
    }

    if (this._lastCountry && this.currentCountry !== this._lastCountry) {
      this._lastCountry = this.currentCountry;
      this._lastCountryGroup = this.getCurrentCountryGroup();
      return Promise.resolve(this.UrlChanger(this._banners, this.currentCountry));
    }

    this._lastCountry = this.currentCountry;
    this._lastCountryGroup = this.getCurrentCountryGroup();
    return Promise.resolve(this._banners);
  }

  private async getImageFromFirebase(bannerGroup: string): Promise<IBanner | undefined> {
    const firestore = this.fireBaseApp.firestore();
    const bannerDoc = firestore.doc(`ads/banners/${bannerGroup}/default`);
    return from(bannerDoc.get())
      .pipe(timeout(5000), take(1))
      .toPromise()
      .then(snapshot => {
        const parsedBanners = this.UrlChanger(snapshot.data() as IBanner, this.currentCountry);
        return (this._banners = parsedBanners);
      });
  }
}
