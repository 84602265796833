// eslint-disable-next-line import/no-extraneous-dependencies, import/no-internal-modules
import { loadDynamicScript } from '@providers/dynamic-js-loader/dynamic-js-loader';
import { mapKeys } from 'lodash-es';
import { bindCallback, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap, timeout } from 'rxjs/operators';

import { Basic } from './device-platform';
import { IDevicePlatform } from './device-platform.interface';
import { getLaunchEventData } from './devices.helper';

export interface IWebosDeviceInfo {
  ddrSize: string;
  dolbyAtmos: boolean;
  dolbyVision: boolean;
  hdr10: boolean;
  modelName: string;
  oled: boolean;
  screenHeight: number;
  screenWidth: number;
  sdkVersion: string;
  uhd: boolean;
  uhd8K: boolean;
  version: string;
  versionDot: number;
  versionMajor: number;
  versionMinor: number;
}

export class Webos extends Basic implements IDevicePlatform {
  name = 'webos';
  type = 'webos';
  year: string;

  deviceData: IWebosDeviceInfo;

  constructor() {
    super();
    const regex = new RegExp('(webOS.TV)-([0-9]{4})');
    const results = navigator.userAgent.match(regex);
    if (results) {
      this.year = results[2];
    }
  }

  getLaunchParams() {
    return getLaunchEventData();
  }

  listenRelaunch() {
    if (!!this.relaunchSubscription) {
      this.relaunchSubscription();
    }

    this.relaunchSubscription = document.addEventListener(
      'webOSRelaunch',
      () => {
        this.onLaunch();
      },
      true
    );
  }

  onLaunch() {
    const launchParamsRedirect = this.getLaunchParams();

    console.assert(launchParamsRedirect !== undefined, 'launchEvent no debería estar indefinido');

    console.assert(launchParamsRedirect);

    if (!!launchParamsRedirect) {
      this.lauchEvent.next(launchParamsRedirect);
    }
  }

  onRelaunch(){
    this.listenRelaunch();
  }

  async onInit(): Promise<void> {
    this.deviceObject = document.getElementById('device') ?? {};

    await loadDynamicScript('/assets/js/webos/webOSTV.js', 'webosLib')
      .pipe(
        // switchMap(() => loadDynamicScript('/assets/js/webos/webOSTV-dev.js', 'webosLibDev')),
        switchMap(() => {
          if ((window as any).webOS.platform.tv) {
            // calls TV specific service APIs
            const deviceInfoAsObservable = bindCallback<IWebosDeviceInfo>((window as any).webOS.deviceInfo);
            return deviceInfoAsObservable()
              .pipe(
                timeout(8000),
                catchError(e => throwError(e))
              )
              .pipe(
                map(async (dataDevice: IWebosDeviceInfo) => {
                  this.deviceData = mapKeys(dataDevice, (value, key) => 'webos_' + key) as any;
                  let systemIinfo = ((window as any) as any).webOS.systemInfo();
                  systemIinfo = mapKeys(dataDevice, (value, key) => 'webos_' + key);

                  this.deviceData = { ...this.deviceData, ...systemIinfo };
                  if (!this.deviceObject.serialNumber) {
                    try {
                      const serialNumber = await this.getLGUDID();
                      this.deviceObject.serialNumber = serialNumber;
                    } catch (e) {
                      // console.log('error on detect serial number', e);
                      const ramdomNumber = Math.floor(Math.random() * 1000000);
                      this.deviceObject.serialNumber = 'UndetectableSerialNumber' + ramdomNumber.toString();
                    }
                  }

                  if (!this.deviceData.modelName) {
                    this.deviceData.modelName = dataDevice.modelName;
                  }
                  return this.deviceData;
                })
              )
              .toPromise()
              .catch(e => {
                this.deviceData = this.deviceData || ({} as any);
                return this.deviceData;
              });
          } else {
            console.log('This device is not TV.', this.deviceData);
            return of(this.deviceData);
          }
        }),
        timeout(8000),
        catchError(e => throwError(e))
      )
      .toPromise();
    super.onInit();
  }

  getLGUDID(): Promise<string> {
    return new Promise((resolve, reject) => {
      // Get system ID information
      (window as any).webOS.service.request('luna://com.webos.service.sm', {
        method: 'deviceid/getIDs',
        parameters: {
          idType: ['LGUDID'],
        },
        onSuccess: (inResponse: { idList: { idValue: string; idType: string }[] }) => {
          // To-Do something
          if (inResponse.idList[0]) {
            resolve(inResponse.idList[0].idValue);
          } else {
            reject(inResponse);
          }
        },
        onFailure: inError => {
          // To-Do something
          reject(inError);
        },
      });
    });
  }

  onResume(router) {}
  exit() {
    try {
      (window as any).webOS.platformBack();
      (window as any).history.go(-999);
      (window as any).close();
    } catch (e) {
      (window as any).history.go(-999);
      (window as any).close();
    }
  }
}
